const { createContext, useContext, useState } = require("react");

const TitleBarTrackerContext = createContext();

const TitleBarTrackerProvider = ({ children }) => {
  const [ isOverlayVisible, setIsOverlayVisible ] = useState('windowControlsOverlay' in navigator ? navigator.windowControlsOverlay.visible : false);

  const debounce = (func, wait) => {
		let timeout;
		return function executedFunction(...args) {
			const later = () => {
				clearTimeout(timeout);
				func(...args);
			};
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
		};
	};

	if ('windowControlsOverlay' in navigator) {
		navigator.windowControlsOverlay.addEventListener('geometrychange', debounce(() => {
			setIsOverlayVisible(navigator.windowControlsOverlay.visible);
	}, 200));
	}

  const value = {
		isOverlayVisible
  };

  return (
		<TitleBarTrackerContext.Provider value={ value }>
			{ children }
		</TitleBarTrackerContext.Provider>
	);
}

const useTitleBarTracker = () => useContext(TitleBarTrackerContext);

export { TitleBarTrackerProvider, useTitleBarTracker};