import { useEffect, useMemo, useState } from "react";
import WeGantt from "../../../components/WeGantt";
import useCalendar from "./hooks/useCalendar";
import useTasks from "./hooks/useTasks";
import { useNotifyContext } from "../../components/Notify";
import req from "../../../modules/Request";
import { useNavigator } from "../../components/Navigator";
import FilterPanel from "./components/FilterPanel";
import ViewPanel from "./components/ViewPanel";
import { Flaticon } from "../../../components/WePack";
import { useContextMenu } from "../../components/ContextMenu";
import * as session from "../../../modules/Session";

const FONT_SIZE = 11;
const CELL_HEIGHT = 20;
const CELL_WIDTH = 20;

const Scheduler = () => {
  const { notify } = useNotifyContext();
  const { setQuickAccessPanel, go, params } = useNavigator();
  const { setContextMenu } = useContextMenu();

  const [ showPanel, setShowPanel ] = useState({ show: false });
  const [ viewMode, setViewMode ] = useState(params[ 'view-mode' ] || session.getStore('view-mode') || 'days');
  const [ showPer, setShowPer ] = useState(params[ 'show-per' ] || session.getStore('show-per') || 'resources');
  const [ range, setRange ] = useState({ from: '', to: '' });

  const { calendar } = useCalendar();
  const { tasks, setTasks } = useTasks({ showPer, calendar, range });
  const [ filters, setFilters ] = useState({
    offices: [],
    travels: [],
    interventions: [],
    installations: []
  });

  const filteredTasks = useMemo(() => (
    tasks?.filter((task) => {
      let isValid = true;

      if (showPer === 'projects') {
        if (filters.interventions.length > 0) {
          isValid = isValid && filters.interventions.includes(task.interventionID);
        }

        if (filters.installations.length > 0) {
          isValid = isValid && filters.installations.includes(task.installationID);
        }
      } else if (showPer === 'resources') {
        if (filters.offices.length > 0) {
          isValid = isValid && filters.offices.includes(task.officeID);
        }

        if (filters.travels.length > 0) {
          isValid = isValid && filters.travels.includes(task.travelID);
        }
      }

      return isValid;
    })
  ), [ tasks, filters, showPer ]);

  useEffect(() => {
    setQuickAccessPanel([
      { name: "Select View", icon: { name: "eye", type: "rr" }, type: "button", onClick: () => setShowPanel(curr => ({ ...curr, show: 'selectView' })) },
      { name: "Filter", icon: { name: "filter", type: "rr" }, type: "button", onClick: () => setShowPanel(curr => ({ ...curr, show: 'filter' })) }
    ]);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <FilterPanel
        viewMode={ viewMode }
        show={ showPanel?.show === 'filter' }
        onClose={ () => setShowPanel(curr => ({ ...curr, show: false })) }
        showPer={ showPer }
        onSubmit={ ({ id, value }) => {
          if (id === 'office') {
            session.addStore('office-filter', value);
            setFilters((curr) => ({ ...curr, offices: value }));
          } else if (id === 'travel') {
            session.addStore('travel-filter', value);
            setFilters((curr) => ({ ...curr, travels: value }));
          } else if (id === 'intervention') {
            session.addStore('intervention-filter', value);
            setFilters((curr) => ({ ...curr, interventions: value }));
          } else if (id === 'installation') {
            session.addStore('installation-filter', value);
            setFilters((curr) => ({ ...curr, installations: value }));
          } else if(id === 'from') {
            console.log(value);
            setRange(curr => ({ ...curr, from: value }));
            setShowPanel(curr => ({ ...curr, show: false }));
          } else if(id === 'to') {
            console.log(value);
            setRange(curr => ({ ...curr, to: value }));
            setShowPanel(curr => ({ ...curr, show: false }));
          }
        } }

        range={ range }

        currOffice={ filters.offices }
        offices={ tasks?.reduce((acc, { office, officeID }) => {
          if (!acc.find((o) => o.value == officeID)) {
            acc.push({ value: officeID, label: office });
          }

          return acc;
        }, []) ?? [] }

        currTravel={ filters.travels }
        travels={ tasks?.reduce((acc, { travelID, travelAvailability }) => {
          if (!acc.find((t) => t.value == travelID)) {
            acc.push({ value: travelID, label: travelAvailability });
          }

          return acc;
        }, []) ?? [] }

        currIntervention={ filters.interventions }
        interventions={ tasks?.reduce((acc, { interventionID, intervention }) => {
          if (!acc.find((i) => i.value == interventionID && interventionID)) {
            acc.push({ value: interventionID, label: intervention });
          }

          return acc;
        }, []) ?? [] }

        currInstallation={ filters.installations }
        installations={ tasks?.reduce((acc, { installationID, installation }) => {
          if (!acc.find((i) => i.value == installationID) && installationID) {
            acc.push({ value: installationID, label: installation });
          }

          return acc;
        }, []) ?? [] }
      />

      <ViewPanel
        viewType={ viewMode }
        showPer={ showPer }
        show={ showPanel?.show === 'selectView' }
        onClose={ () => setShowPanel(curr => ({ ...curr, show: false })) }
        onSubmit={ ({ key, value }) => {
          if (key === 'viewType') {
            session.addStore('view-mode', value);
            setViewMode(value);
          } else if (key === 'showPer') {
            session.addStore('show-per', value);
            setTasks([]);
            setShowPer(value);
          }
        } }
      />

      { tasks.length > 0 && (
        filteredTasks.length > 0 ? (
          <WeGantt
            viewMode={ viewMode }
            displayHolidays={ calendar.holidays }
            styles={ { holidayColor: '#f0f0f0', todayColor: '#AAAAFF' } }
            tasks={ filteredTasks }
            cellHeight={ CELL_HEIGHT }
            cellWidth={ CELL_WIDTH }
            fontSize={ FONT_SIZE }
            onMove={ (task) => {
              if (showPer === 'projects') {
                setTasks(tasks.map((t) => {
                  if (t.id == task.id) {
                    req('update-task', {
                      id: task.id.replace('task-', ''),
                      start: task.start,
                      end: task.end,
                      showPer: 'projects'
                    }).then((res) => {
                      if (res.error) {
                        notify("Move Task Error", "Please contact the administrator", 'error');
                      }
                    });

                    return {
                      ...t,
                      start: task.start,
                      end: task.end,
                      duration: undefined
                    };
                  } else {
                    return t;
                  }
                }));
              } else {
                setTasks(tasks.map((t) => {
                  if (t.id == task.id && t.dates.length == task.dates.length) {
                    const split = task.dates.find((d) => d.id == task.splitID);
                    req('update-task', { id: task.splitID, start: split.start, end: split.end, showPer: 'resources' }).then((res) => {
                      if (res.error) {
                        notify("Move Task Error", "Please contact the administrator", 'error');
                      }
                    });

                    return {
                      ...t,
                      start: task.start,
                      end: task.end,
                      duration: undefined,
                      dates: task.dates.map((d, i) => {
                        return {
                          ...d,
                          duration: undefined,
                        };
                      })
                    };
                  } else {
                    return t;
                  }
                }));
              }
            } }
            onResize={ (task) => {
              if (showPer === 'projects') {

                setTasks(tasks.map((t) => {
                  if (t.id == task.id) {
                    req('update-task', { id: task.id.replace('task-', ''), start: task.start, end: task.end, showPer: 'projects' }).then((res) => {
                      if (res.error) {
                        notify("Resize Task Error", "Please contact the administrator", 'error');
                      }
                    });

                    return {
                      ...task,
                      duration: undefined
                    };
                  } else {
                    return t;
                  }
                }));
              } else {
                setTasks(tasks.map((t) => {
                  if (t.id == task.id && t.dates.length == task.dates.length) {
                    const split = task.dates.find((d) => d.id == task.splitID);
                    req('update-task', { id: task.splitID, start: split.start, end: split.end, showPer: 'resources' }).then((res) => {
                      if (res.error) {
                        notify("Resize Task Error", "Please contact the administrator", 'error');
                      }
                    });

                    return {
                      ...task,
                      duration: undefined,
                      dates: task.dates.map((d, i) => {
                        return {
                          ...d,
                          duration: undefined,
                        };
                      })
                    };
                  } else {
                    return t;
                  }
                }));
              }
            } }

            onTaskContextMenu={ ({ task, event }) => {
              if (showPer === 'resources' && task.split.record === 'task') {
                setContextMenu({
                  context: [ [
                    {
                      name: 'Edit', icon: { name: 'edit', type: 'rr' }, onClick: () => {
                        go('data-management', {
                          "form-modal": { show: 'projects-form', id: task.split.projectID },
                          "selected-topic": "projects"
                        });
                      }
                    }
                  ] ],
                  position: { x: event.clientX, y: event.clientY }
                });
              }
            } }

            workingHours={ 8 }

            TableColumns={ () => {
              if (showPer === 'projects') {
                return [
                  <div className="flex px-2 min-w-32 w-full">Name</div>
                ];
              } else {
                return [
                  <div className="flex px-2 min-w-32 w-full">Name</div>,
                  <div className="flex px-2 min-w-32 w-full">Office</div>,
                  <div className="flex px-2 min-w-32 w-full">Travel</div>
                ];
              }
            } }

            TableRow={ ({ name, office, travelAvailability, space, showChilds, id, record }) => {
              if (showPer === 'projects') {
                return (
                  <tr
                    onContextMenu={ (e) => {
                      e.preventDefault();
                      if (record === 'project') {
                        setContextMenu({
                          context: [ [
                            {
                              name: 'Edit', icon: { name: 'edit', type: 'rr' }, onClick: () => {
                                go('data-management', {
                                  "form-modal": { show: 'projects-form', id },
                                  "selected-topic": "projects"
                                });
                              }
                            }
                          ] ],
                          position: { x: e.clientX, y: e.clientY }
                        });
                      }
                    } }
                    style={ { padding: (space * 10) + 'px' } }
                  >
                    <td style={ { fontSize: FONT_SIZE, height: CELL_HEIGHT } } className="min-w-12 px-2 py-0 border-b border-gray-200 border-r">
                      { showChilds !== undefined && (
                        <button onClick={ () => setTasks(curr => curr.map((t) => t.id == id ? { ...t, showChilds: !t.showChilds } : t)) }>
                          { showChilds ? (
                            <Flaticon name="angle-down" className="pr-1" type="rr" size={ FONT_SIZE } />
                          ) : (
                            <Flaticon name="angle-right" className="pr-1" type="rr" size={ FONT_SIZE } />
                          ) }
                        </button>
                      ) }
                      <span>{ name }</span>
                    </td>
                  </tr>
                );
              } else {

                return (
                  <tr
                    style={ { fontSize: FONT_SIZE, height: '20px' } }
                    className="*:min-w-10 *:border-gray-200 *:h-full"
                  >
                    <td style={ { paddingLeft: (space * 10) + 10 } } className="border-b border-r">
                      { name }
                    </td>
                    <td className="px-2 border-b border-r">
                      { office }
                    </td>
                    <td className="px-2 border-b">
                      { travelAvailability }
                    </td>
                  </tr>
                );
              }
            } }
          />
        ) : (
          <div className="flex justify-center items-center w-full h-full">
            <div className="flex flex-col justify-center items-center">
              With this filter, there are no tasks to display
            </div>
          </div>
        )
      ) }
    </div>
  );
};

export default Scheduler;