

const Dashboard = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <h1 className="text-3xl">Dashboard</h1>
    </div>
  );
}

export default Dashboard;