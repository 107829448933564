const setSession = (session) => {
  localStorage.setItem('session', JSON.stringify(session));
}

const getSession = () => {
  return JSON.parse(localStorage.getItem('session'));
}

const delSession = () => {
  localStorage.removeItem('session');
}

const setParams = (params) => {
  const session = getSession();
  session.params = params;
  setSession(JSON.stringify(session));
}

const getParams = () => {
  const session = getSession();
  return session?.params;
}

const addParam = (key, value) => {
  const session = getSession();
  session.params[key] = value;
  setSession(session); 
}

const getParam = (key) => {
  const session = getSession();
  return session.params[key];
}

const delParam = (key) => {
  const session = getSession();
  delete session.params[key];
  setSession(session);
}

const setActive = (active) => {
  const session = getSession();
  session.active = active;
  setSession(session);
}

const getActive = () => {
  const session = getSession();
  return session?.active;
}

const addStore = (key, value) => {
  const store = JSON.parse(localStorage.getItem('store') || '{}');
  store[key] = value;
  localStorage.setItem('store', JSON.stringify(store));
}

const getStore = (key) => {
  const store = JSON.parse(localStorage.getItem('store') || '{}');
  return store?.[key];
}

export {
  setSession,
  getSession,
  delSession,
  addParam,
  getParam,
  delParam,
  setParams,
  getParams,
  setActive,
  getActive,
  addStore,
  getStore
};