import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotifyContext } from "../../../components/Notify";
import * as session from "../../../../modules/Session";

const useWarrantyOptions = ({ show }) => {
  const [ warrantyOptions, setWarrantyOptions ] = useState(session.getStore("warrantyOptions") || []);
  const { notify } = useNotifyContext();

  useEffect(() => {
    const fetchNations = async () => {
      const res = await req("select-values", { type: "GARANZIA" });

      if (res.success) {
        session.addStore("warrantyOptions", res.data);
        setWarrantyOptions(res.data);
      } else {
        notify("Error", "Please contact the administrator", 'error');
      }
    };

    if (show) fetchNations();
  }, [ show ]);

  return warrantyOptions;
};

export default useWarrantyOptions;