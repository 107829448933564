
import { useState, useEffect } from 'react';
import { useNotifyContext } from '../../../components/Notify';
import req from '../../../../modules/Request';
import { useLoadingContext } from '../../../components/LoadingScreen';
import { useAuth } from '../../../components/Auth';

const useTasks = ({ showPer, range }) => {
  const { notify } = useNotifyContext();
  const { userData: { permissions, username } } = useAuth();
  const { setShowLoadingScreen, showLoadingScreen } = useLoadingContext();

  const [ tasks, setTasks ] = useState([]);
  
  useEffect(() => {
    if(showPer === "resources") { 
      const fetchResourceTasks = async () => {
        setShowLoadingScreen(true);
        
        const res = await req('select-tasks', { showPer, from: range.from, to: range.to });

        if(res.success) {
          setTasks(res.data.map((resource) => {
            return {
              ...resource,
              isDraggable: true,
              type: 'task',
              dates: resource.tasks.map((task) => {
                return { 
                  ...task,
                  isDraggable: permissions.includes('SSP_AM_SCH') || (permissions.includes('SSP_SCH') && resource.managerID === username) ? (
                    task.isDraggable
                  ) : false,
                  isResizable: permissions.includes('SSP_AM_SCH') || (permissions.includes('SSP_SCH') && resource.managerID === username) ? (
                    task.isResizable
                  ) : false
                };
              })
            };
          }));
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }

        setShowLoadingScreen(false);
      };

      fetchResourceTasks();
    } else if(showPer === "projects") {
      const fetchProjectTasks = async () => {
        setShowLoadingScreen(true);

        const res = await req('select-tasks', { showPer, from: range.from, to: range.to });

        if(res.success) {
          setTasks(res.data.map((task) => {
            return {
              ...task,
              isDraggable: task.type === 'task' && permissions.includes('SSP_AM_SCH') || (permissions.includes('SSP_SCH') && task.managerID === username) ? (
                task.isDraggable
              ) : false,
              isResizable: task.type === 'task' && permissions.includes('SSP_AM_SCH') || (permissions.includes('SSP_SCH') && task.managerID === username) ? (
                task.isResizable
              ) : false
            };
          }));
        } else {
          notify("Error", "Please contact the administrator", 'error');
        }

        setShowLoadingScreen(false);
      };
      
      fetchProjectTasks();
    } else {
      notify("Error", "Please contact the administrator", 'error');
    }
  }, [ showPer, range ]);
  
  return { tasks, setTasks };
}

export default useTasks;