import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ContexturePanel, ContextureButton, ContextureCategory, Flaticon, FormifyInput, FormifyMultiSelect, FormifyMultiOption, FormifyMultiAll } from '../../../../components/WePack';
import { clsx } from '../../../../modules/Utils';

const OPTIONS_X = 148;
const OPTIONS_Y = 100;
const OPTIONS_OFFSET_Y = 23;

const FilterPanel = React.memo(({
  show,
  onClose,
  onSubmit,
  showPer,
  currOffice,
  offices,
  currTravel,
  travels,
  currIntervention,
  interventions,
  currInstallation,
  installations,
  range
}) => {
  const [ tempRange, setTempRange ] = useState({ from: range.from, to: range.to });

  const syncRange = useRef(null);

  const timoutMouseEnter = useRef(null);
  const filters = {
    "resources": [
      { position: 0, label: "Office", id: 'office', currValue: currOffice, options: [ ...offices ] },
      { position: 1, label: "Travel", id: 'travel', currValue: currTravel, options: [ ...travels ] }
    ],
    "projects": [
      { position: 0, label: "Intervention", id: 'intervention', currValue: currIntervention, options: [ ...interventions ] },
      { position: 1, label: "Installation", id: 'installation', currValue: currInstallation, options: [ ...installations ] }

    ]
  };

  const [ showOptions, setShowOptions ] = useState({ show: false, position: { x: 0, y: 0 }, filterID: undefined });

  const selectedFilter = filters[ showPer ].find(({ id }) => id === showOptions.filterID);

  const handleClosePanels = () => {
    if (syncRange.current.from !== '' && syncRange.current.from !== range.from) {
      onSubmit({ id: 'from', value: syncRange.current.from });
    }

    if (syncRange.current.to !== '' && syncRange.current.to !== range.to) {
      onSubmit({ id: 'to', value: syncRange.current.to });
    }

    clearTimeout(timoutMouseEnter.current);
    timoutMouseEnter.current = null;
    setShowOptions(curr => ({ ...curr, show: false }));
    onClose();
  };

  const handleFilterPanelMouseEnter = (e, id, currValue, position) => {
    if (timoutMouseEnter.current) {
      setShowOptions(curr => ({ ...curr, show: false }));

      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }

    timoutMouseEnter.current = setTimeout(() => {
      setShowOptions({
        show: true,
        position: { x: OPTIONS_X, y: OPTIONS_Y + (OPTIONS_OFFSET_Y * position) },
        filterID: id,
        value: currValue
      });
    }, 500);
  };

  const handleFilterPanelMouseLeave = () => {
    if (timoutMouseEnter.current) {
      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }
  };

  const handleSelectFilter = (e, id, currValue, position) => {
    if (timoutMouseEnter.current) {
      setShowOptions(curr => ({ ...curr, show: false }));

      clearTimeout(timoutMouseEnter.current);
      timoutMouseEnter.current = null;
    }

    setShowOptions({
      show: true,
      position: { x: OPTIONS_X, y: OPTIONS_Y + (OPTIONS_OFFSET_Y * position) },
      filterID: id,
      value: currValue
    });
  };

  const handleCloseOptionPanel = () => {
    setShowOptions(curr => ({ ...curr, show: false }));
  };

  useEffect(() => {
    syncRange.current = { from: tempRange.from, to: tempRange.to };
  }, [ tempRange ]);

  return (
    <>
      { /* FILTER BY */ }
      <ContexturePanel simple
        show={ show }
        onClose={ () => handleClosePanels() }
        position={ { x: 180, y: 40 } }
      >
        <ContextureCategory label="Filter by Date" addClasses="space-y-1">
          <FormifyInput
            type="date"
            value={ tempRange.from }
            addClasses="[padding:0_6px!important]"
            onInput={ (value) => setTempRange({ ...tempRange, from: value }) }
          />

          <FormifyInput
            type="date"
            value={ tempRange.to }
            addClasses="[padding:0_6px!important]"
            onInput={ (value) => setTempRange({ ...tempRange, to: value }) }
          />

        </ContextureCategory>
        <ContextureCategory label="Filter by">
          { filters[ showPer ].map(({ label, id, currValue, position }) => (
            <ContextureButton
              key={ id }
              onMouseEnter={ (e) => handleFilterPanelMouseEnter(e, id, currValue, position) }
              onMouseLeave={ handleFilterPanelMouseLeave }
              onClick={ (e) => handleSelectFilter(e, id, currValue, position) }
            >
              <span className='w-full text-start'>{ label }</span>
              <Flaticon name="angle-right" type="rr" size={ 11 } />
            </ContextureButton>
          )) }
        </ContextureCategory>

        { /* OPTIONS LIST */ }
        <ContexturePanel simple outClose
          show={ showOptions.show }
          onClose={ handleCloseOptionPanel }
          position={ showOptions.position }
        >
          <ContextureCategory label={ selectedFilter?.label }>
            <FormifyMultiSelect
              values={ selectedFilter?.currValue ?? [] }
              onChange={ (value) => {
                onSubmit({ id: selectedFilter.id, value });
              } }
            >
              <FormifyMultiAll className="flex w-full items-center">
                { ({ isSelectedAll, isSelectedHalf }) => {
                  return (
                    <>
                      { isSelectedAll ? (
                        <Flaticon name="checkbox" type="br" size={ 13 } className="w-4" />
                      ) : isSelectedHalf ? (
                        <Flaticon name="square-minus" type="br" size={ 13 } className="w-4" />
                      ) : (
                        <Flaticon name="square" type="br" size={ 13 } className="w-4" />
                      ) }
                      <span>All</span>
                    </>
                  );
                } }
              </FormifyMultiAll>

              { selectedFilter?.options.map(({ value, label }) => (
                <FormifyMultiOption key={ value } value={ value } className="flex w-full items-center">
                  { ({ isSelected }) => {
                    return (
                      <>
                        { isSelected ? (
                          <Flaticon name="checkbox" type="br" size={ 13 } className="w-4" />
                        ) : (
                          <Flaticon name="square" type="br" size={ 13 } className="w-4" />
                        ) }
                        <span>{ label }</span>
                      </>
                    );
                  } }
                </FormifyMultiOption>
              )) }
            </FormifyMultiSelect>
          </ContextureCategory>
        </ContexturePanel>
      </ContexturePanel>
    </>
  );
});

export default FilterPanel;